<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="categories"
      :items-per-page="10"
      :loading="loading"
      loading-text="Loading... Please wait"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title
            class="headline pb-0 pt-2"
            style="text-transform: capitalize"
          >
            {{ categoryName }} Categories
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            max-width="500"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New {{ categoryName }} Category
              </v-btn>
            </template>
            <NewCategoryForm
              :return-route="returnRoute"
              :category-name="categoryName"
              :rest-end-point="restEndPoint"
              @created="handleCreatedCategory"
              @canceled="handleCanceled"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <EditAndDeleteButtons
          :edit-href="`${returnRoute}/${item._id}`"
          @delete="deleteCategory(item._id)"
        />
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import NewCategoryForm from './GeneralCategoryForm'
import { mapActions } from 'vuex'
import EditAndDeleteButtons from '@/components/tables/EditAndDeleteButtons.vue'

export default {
  name: 'AdminCategories',
  components: {
    NewCategoryForm,
    EditAndDeleteButtons
  },
  props: {
    categoryName: {
      type: String,
      required: true
    },
    restEndPoint: {
      type: String,
      required: true
    },
    returnRoute: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: true,
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'Notes',
          value: 'notes'
        },
        {
          value: 'actions',
          sortable: false
        }
      ],
      categories: [],
      showForm: false
    }
  },
  async created () {
    await this.fetchCategories()
    this.loading = false
  },
  methods: {
    ...mapActions(['showSnackbar']),

    async fetchCategories () {
      const { data: categories } = await this.$axios.get(`${this.restEndPoint}`)
      this.categories = categories.map(category => ({
        ...category
      }))
    },
    async deleteCategory (id) {
      this.loading = true
      try {
        await this.$axios.delete(`${this.restEndPoint}/${id}`)
        await this.fetchCategories()
      } catch (err) {
        await this.showSnackbar({ color: 'error', message: `Unable to delete ${this.categoryName} category. Please try again.` })
        throw err
      }
      this.loading = false
    },
    handleCanceled () {
      this.showForm = false
    },
    handleCreatedCategory () {
      this.loading = true
      this.showForm = false
      this.fetchCategories()
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
